import React, { useState } from 'react';
import { handleRequest } from '../../services/handleRequest';
import { IoMdClose } from 'react-icons/io';
// Styles
import './CardRequest.css';

const CardRequest = ({ element, couponUpdateHandler }) => {
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [requestSuccessful, setRequestSuccessful] = useState('');

  const [action, setAction] = useState('');
  const [reason, setReason] = useState('');

  const confirmationHandler = (action) => {
    setConfirmationModal(!confirmationModal);
    setAction(action);
  };

  const requestHandler = async () => {
    if (!reason.trim()) {
      alert('Por favor, proporciona una razón antes de confirmar.');
      return;
    }
    try {
      const res = await handleRequest(
        element.id,
        action === 'aceptar' ? 2 : 3,
        reason,
        element.empleado_id,
        element.fecha_solicitud.split('T')[0],
        element.beneficio_id
      );
      if (res.status === 200) {
        setRequestSuccessful('successful');
      } else {
        setRequestSuccessful('error');
      }
    } catch (error) {
      console.error('Error al realizar la solicitud:', error);
      setRequestSuccessful('error');
    } finally {
      setTimeout(() => {
        setConfirmationModal(false);
        setRequestSuccessful('');
        couponUpdateHandler(
          `updated benefit ${element.beneficio_id} requested by ${element.nombre}`
        );
      }, 2500);
    }
  };

  const errorMessage = requestSuccessful === 'error' && (
    <p className='errorMessage'>Error al procesar la solicitud</p>
  );

  const successfulMessage = requestSuccessful === 'successful' && (
    <p className='successfulMessage'>Solicitud procesada correctamente</p>
  );

  return (
    <div className='cardRequest'>
      <div className='cardRequestContainer'>
        {confirmationModal && (
          <div className='modal-overlay'>
            <div className='modal'>
              <div className='modal-info'>
                <p>
                  ¿Deseas <b>{`${action}`}</b> la solicitud?
                </p>
                <p className='benefitName'>
                  {element.beneficio}{' '}
                  {element.detalle && `(Jornada en la ${element.detalle})`}
                </p>
                <p>
                  Empleado: <b>{element.nombre}</b>
                </p>

                <div className='reasonContainer'>
                  <label htmlFor='reason'>Razón:</label>
                  <textarea
                    id='reason'
                    name='story'
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                  ></textarea>
                </div>

                {requestSuccessful === '' && (
                  <button className='requestButton' onClick={requestHandler}>
                    Confirmar
                  </button>
                )}
                {errorMessage}
                {successfulMessage}
              </div>
              <button className='closeButton' onClick={() => setConfirmationModal(false)}>
                <IoMdClose  />
              </button>
            </div>
          </div>
        )}
        <div className='cardRequestContainer__actions'>
          <div className='cardRequestContainer__information'>
            <div>
              <p>
                <b>Solicitud: </b>
              </p>
              <p>
                <b>Empleado: </b>
              </p>
              <p>
                <b>Email:</b>
              </p>
              <p>
                <b>Fecha:</b>
              </p>
            </div>
            <div className='cardRequestContainer__informationUser'>
              <p>
                <b>
                  {element.beneficio}{' '}
                  {element.detalle && `(Jornada en la ${element.detalle})`}
                </b>
              </p>
              <p>{element.nombre}</p>
              <p>{element.correo}</p>
              <p>{element.fecha_solicitud.split('T')[0]}</p>
            </div>
          </div>
          <div className='cardRequestContainerButtons'>
            <button onClick={() => confirmationHandler('aceptar')}>
              Aceptar
            </button>
            <button onClick={() => confirmationHandler('rechazar')}>
              Rechazar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardRequest;
